import React from 'react';
import CanvasLmsIcon from 'assets/icons/canvas-lms';
import Button from 'components/elements/Button';
import { CloseIcon } from 'components/experience/controls/voiceSelector/icons';
import { useRouter } from 'next/router';
import { logSegmentEvent } from 'utils/analytics';

export default function CanvasBanner({ positionUp, closed, onClose }: { positionUp: boolean; closed: boolean; onClose: () => void }) {
  const { push } = useRouter();
  const handleClick = () => {
    logSegmentEvent('web_app_canvas_banner_connect_clicked');
    push({
      pathname: '/settings',
      query: {
        tab: 'Integrations',
        integration: 'canvas'
      }
    });
  };

  const handleClose = () => {
    logSegmentEvent('web_app_canvas_banner_closed');
    onClose();
  };

  if (closed) return null;

  return (
    <div
      className="absolute bottom-8 flex w-full flex-col gap-2 rounded-xl bg-electric-200 px-5 py-4"
      style={{ maxWidth: '250px', ...(positionUp && { marginBottom: '6rem' }) }}
    >
      <CloseIcon onClick={handleClose} className="absolute right-4 top-3 h-4 w-4 cursor-pointer fill-glass-500 hover:fill-glass-400" />
      <div>
        <CanvasLmsIcon />
      </div>
      <h3 className="font-ABCDiatype text-lg	font-bold">Sync your school reading</h3>
      <p className="font-ABCDiatype text-sm font-normal text-glass-700">
        Import all your reading materials from your Canvas account and learn faster by listening
      </p>
      <div>
        <Button onClick={handleClick} full className="text-base font-bold">
          Connect Canvas
        </Button>
      </div>
    </div>
  );
}
