import React from 'react';

import Button from 'components/elements/Button';
import useTranslation from 'next-translate/useTranslation';

type Props = {
  variant: 'subItem' | 'modal';
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
  setModal?: React.Dispatch<React.SetStateAction<string>>;
} & React.ComponentProps<'div'>;

function ListenToYourDoc({ variant, onClick }: Props) {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={variant === 'subItem' ? 'checklist-subitem-wrapper' : 'checklist-modal-wrapper'}>
        {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Upload your first document')}</h4>}
        <p>{t('Install the Speechify extension to listen to any webpages and to unlock superpower on Google doc and Gmail')}</p>
        {/* ESLint: Do not pass children as props */}
        {/* eslint-disable-next-line react/no-children-prop */}
        <Button full children="Go to Google Doc" onClick={onClick} className="text-base font-bold " />
      </div>
    </div>
  );
}

export default ListenToYourDoc;
