import React from 'react';

import Button from 'components/elements/Button';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch } from 'store';
import { actions as authActions } from 'store/auth';
import { actions as libraryActions } from 'store/library';
import { logSegmentEvent } from 'utils/analytics';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';

type Props = {
  variant: 'subItem' | 'modal';
  setModal?: React.Dispatch<React.SetStateAction<string>>;
} & React.ComponentProps<'div'>;

function AddYourFirstItem({ variant, setModal }: Props) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleAddItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(libraryActions.maximizeChecklist(false));
    dispatch(libraryActions.showAddNewWithBackdrop());

    dispatch(authActions.setFileUploaded(true));
    setCustomAccountSetting('fileUploaded', 'true');

    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'upload file' });

    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    if (variant === 'modal') setModal('');
  };

  return (
    <div className={variant === 'subItem' ? 'checklist-subitem-wrapper' : 'checklist-modal-wrapper'}>
      {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Upload your first document')}</h4>}
      <p>{t(' You can upload PDFs, text documents, and web links.')}</p>
      {/* ESLint: Do not pass children as props */}
      {/* eslint-disable-next-line react/no-children-prop */}
      <Button full children="Upload Document" onClick={handleAddItemClick} className="text-base font-bold " />
    </div>
  );
}

export default AddYourFirstItem;
