import { useCallback } from 'react';

import { useSelector } from 'store';
import { IRouter } from 'interfaces';
import { measure } from '../lib/observability';

export function useMeasureFromNavigationStart() {
  const { navigationStart, clientNavigation } = useSelector<IRouter>(state => state.router);

  return useCallback(
    (name: string) => {
      if (clientNavigation) {
        measure(`client_navigation_${name}`, performance.now() - navigationStart);
      } else {
        measure(name, performance.now());
      }
    },
    [navigationStart, clientNavigation]
  );
}
