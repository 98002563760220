import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'store';
import { actions as darkModeActions, selectors as darkModeSelectors } from 'store/darkMode';
import { isItemOrSharePage } from 'utils';

export const useDarkMode = (instantListeningHack?: boolean) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(darkModeSelectors.getIsDarkMode());
  const isManualDarkMode = useSelector(darkModeSelectors.getIsManual());

  const handleDarkThemeChange = useCallback(
    ({ matches }: $TSFixMe) => {
      if (!isManualDarkMode) {
        if (matches) {
          dispatch(darkModeActions.setDarkMode({ isDarkMode: true }));
        } else {
          dispatch(darkModeActions.setDarkMode({ isDarkMode: false }));
        }
      }
    },
    [isManualDarkMode, dispatch]
  );

  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    handleDarkThemeChange(darkModeQuery);

    darkModeQuery.addEventListener('change', handleDarkThemeChange);

    return () => {
      darkModeQuery.removeEventListener('change', handleDarkThemeChange);
    };
  }, [handleDarkThemeChange]);

  useEffect(() => {
    if (isDarkMode && (isItemOrSharePage() || instantListeningHack)) {
      document.documentElement.classList.add('dark');
      document.body.style.backgroundColor = '#14202E';
    } else {
      document.documentElement.classList.remove('dark');
      document.body.style.backgroundColor = '#F1F4F9';
    }

    return () => {
      document.documentElement.classList.remove('dark');
      document.body.style.backgroundColor = '#F1F4F9';
    };
  }, [isDarkMode, instantListeningHack]);
};
