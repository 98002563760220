import React from 'react';

import Button from 'components/elements/Button';
import useTranslation from 'next-translate/useTranslation';

type DownloadMobileAppProps = {
  variant: 'subItem' | 'modal';
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
} & React.ComponentProps<'div'>;

function DownloadMobileApp({ variant, onClick }: DownloadMobileAppProps) {
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={variant === 'subItem' ? 'checklist-subitem-wrapper' : 'checklist-modal-wrapper'}>
        {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Download apps')}</h4>}
        <p>{t('You can scan printed text and listen to your library on the go')}</p>
        {/* ESLint: Do not pass children as props */}
        {/* eslint-disable-next-line react/no-children-prop */}
        <Button full children="Download apps" onClick={onClick} className="text-base font-bold " />
      </div>
    </div>
  );
}

export default DownloadMobileApp;
