import React, { useEffect, useRef } from 'react';

import { useDispatch } from 'store';
import { actions as onboardingActions } from 'store/onboarding';
import { useIntersectionObserver } from 'usehooks-ts';

export const OnboardingCompletedNotification = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const obs = useIntersectionObserver(ref, {});
  const isVisible = !!obs?.isIntersecting;

  useEffect(() => {
    // ESLint: Unexpected any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;

    if (isVisible) {
      timeout = setTimeout(() => {
        dispatch(onboardingActions.setOnboardingCompletedNotificationDismissed(true));
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className="fixed bottom-6 left-6 z-2000 flex cursor-pointer select-none items-center rounded-xl bg-electric-350 p-3 font-ABCDiatype text-xs text-white shadow-xl sm:min-w-[24rem] sm:max-w-sm"
      onClick={() => {
        dispatch(onboardingActions.setOnboardingCompletedNotificationDismissed(true));
      }}
    >
      <div className="mr-2.5">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-3xl">🎉</div>
      </div>
      <div>
        <div>Congratulations on completing your onboarding. You have covered the essential parts of the web application.</div>
        <div>Enjoy your listening!</div>
      </div>
    </div>
  );
};
