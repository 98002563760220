import { useCallback } from 'react';

import { useDispatch, useSelector } from 'store';
import { autoScrollSelector, preferencesActions } from 'store/preferences';

export function useAutoScroll() {
  const autoScroll = useSelector(autoScrollSelector);
  const dispatch = useDispatch();

  const setAutoScroll = useCallback(
    (value: boolean) => {
      dispatch(preferencesActions.setAutoscroll(value));
    },
    [dispatch]
  );

  const toggleAutoscroll = useCallback(() => {
    dispatch(preferencesActions.toggleAutoscroll());
  }, [dispatch]);

  return { autoScroll, setAutoScroll, toggleAutoscroll };
}
