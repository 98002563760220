import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import Button from 'components/elements/Button';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useDispatch, useSelector } from 'store';
import { actions as importActions } from 'store/import';
import { actions as libraryActions } from 'store/library';
import { actions as onboardingActions } from 'store/onboarding';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';

const CheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0013 18.3337C14.552 18.3337 18.3346 14.5591 18.3346 10.0003C18.3346 5.44967 14.5438 1.66699 9.99313 1.66699C5.43431 1.66699 1.66797 5.44967 1.66797 10.0003C1.66797 14.5591 5.44248 18.3337 10.0013 18.3337ZM8.87385 14.1915C8.59607 14.1915 8.38366 14.069 8.14673 13.7912L5.79379 10.8827C5.67124 10.7275 5.59771 10.5314 5.59771 10.3516C5.59771 9.96765 5.9 9.67353 6.25947 9.67353C6.4964 9.67353 6.69248 9.7634 6.88856 10.0167L8.84117 12.5412L12.6402 6.45457C12.7954 6.20131 13.0078 6.07876 13.2203 6.07876C13.5797 6.07876 13.931 6.31569 13.931 6.70784C13.931 6.88758 13.8248 7.08366 13.7186 7.24706L9.5683 13.7912C9.38856 14.0608 9.1598 14.1915 8.87385 14.1915Z"
      fill="#778FAD"
    />
  </svg>
);

const UncheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.0069" cy="10.0049" r="7.335" fill="#E6E8FF" stroke="#6A79FD" strokeWidth="2" />
  </svg>
);

const ChevronUpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.82861 6.66699C10.085 6.67432 10.3193 6.76953 10.5098 6.97461L16.0762 12.6729C16.2373 12.834 16.3252 13.0391 16.3252 13.2808C16.3252 13.7642 15.9443 14.1523 15.4609 14.1523C15.2266 14.1523 14.9995 14.0571 14.8311 13.8887L9.83594 8.75439L4.82617 13.8887C4.65771 14.0498 4.43799 14.1523 4.19629 14.1523C3.71289 14.1523 3.33203 13.7642 3.33203 13.2808C3.33203 13.0391 3.41992 12.834 3.58105 12.6729L9.15479 6.97461C9.35254 6.76953 9.57227 6.66699 9.82861 6.66699Z"
      fill="#587393"
    />
  </svg>
);

const ChevronDownIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.82861 14.1523C10.085 14.145 10.3193 14.0498 10.5098 13.8447L16.0762 8.14648C16.2373 7.98535 16.3252 7.78027 16.3252 7.53857C16.3252 7.05518 15.9443 6.66699 15.4609 6.66699C15.2266 6.66699 14.9995 6.76221 14.8311 6.93066L9.83594 12.0649L4.82617 6.93066C4.65771 6.76953 4.43799 6.66699 4.19629 6.66699C3.71289 6.66699 3.33203 7.05518 3.33203 7.53857C3.33203 7.78027 3.41992 7.98535 3.58105 8.14648L9.15479 13.8447C9.35254 14.0498 9.57227 14.1523 9.82861 14.1523Z"
      fill="#587393"
    />
  </svg>
);

const ChecklistItem = ({ title, child, completed, openDefault = false }: { title: string; child: ReactNode; completed: boolean; openDefault?: boolean }) => {
  const [open, setOpen] = useState(openDefault);
  return (
    <div className={`mx-4 my-3 flex flex-col font-ABCDiatype text-lg ${completed ? 'text-glass-400' : 'text-glass-700'}`}>
      <div className="flex justify-between">
        <div className="flex select-none">
          <div className="mr-1 flex w-6 items-center justify-center">{completed ? <CheckedIcon /> : <UncheckedIcon />}</div>
          <div>{title}</div>
        </div>
        {!completed && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        )}
      </div>
      {open && !completed ? <div className="pb-1 pl-7 pr-4 ">{child}</div> : null}
    </div>
  );
};

const Checklist = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const openDocumentUpload = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(importActions.openImportDialog());
      dispatch(onboardingActions.setChecklistShowing(false));
    },
    [dispatch]
  );

  const totalItemCount = useSelector(state => state.library.totalItemCount);
  const learnedSpeechifyBasics = useSelector(state => state.onboarding.readerOnboardingCompleted);
  const [progressBlockItems, setProgressBlockItems] = useState<boolean[]>([]);
  const chromeExtensionInstalled = useSelector(state => state.usage.userData.chromeExtension !== null);

  const show = useSelector(state => state.onboarding.checklistShowing);
  const googleDocOpened = useSelector(state => state.onboarding.openedGoogleDoc);

  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);

  useEffect(() => {
    dispatch(libraryActions.fetchItemsCount());
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(onboardingActions.setOnboardingCompleted(learnedSpeechifyBasics && hasUploadedDocument && googleDocOpened));
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnedSpeechifyBasics, hasUploadedDocument, googleDocOpened]);

  useEffect(() => {
    setHasUploadedDocument(totalItemCount > 0);
  }, [totalItemCount]);

  useEffect(() => {
    let potentialItems = [true];

    potentialItems = [...potentialItems, hasUploadedDocument, googleDocOpened];

    const completedItemsCount = potentialItems.filter(v => v).length;
    const total = potentialItems.length;
    const percentage = Math.trunc((completedItemsCount * 100) / total);

    dispatch(onboardingActions.setProgress({ percentage, itemIndex: completedItemsCount - 1 }));
    const orderedItems = potentialItems.map(x => x).sort((a, b) => (a ? -1 : b ? 1 : 0));

    setProgressBlockItems(orderedItems);
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnedSpeechifyBasics, hasUploadedDocument, googleDocOpened]);

  const onSnoozeTips = () => {
    dispatch(onboardingActions.setChecklistShowing(false));
    logSegmentEvent('web_app_onboarding_snooze_tips', { location: 'library' });
  };

  return show ? (
    <div className="fixed bottom-24 right-24 z-2000 rounded-lg bg-white shadow-xl sm:min-w-[24rem] sm:max-w-sm">
      <div className="w-100">
        <div className="flex w-full flex-col items-center rounded-t-lg bg-glass-300 p-4 font-ABCDiatype">
          <div className="mb-3 flex w-full items-center justify-between">
            <span className="select-none text-xl text-glass-700">Get started</span>
            <button className="text-md text-glass-500" onClick={onSnoozeTips}>
              Snooze tips
            </button>
          </div>
          <div className={twMerge('flex', 'w-full')}>
            {progressBlockItems.map((item, i) => {
              const style = i === 0 ? 'rounded-l mr-0.5' : i === progressBlockItems.length - 1 ? 'rounded-r' : 'mr-0.5';
              return <div key={i} className={`h-2 w-full ${item ? 'bg-glass-400' : 'bg-white'} ${style}`}></div>;
            })}
          </div>
        </div>
        <div className="flex flex-col ">
          <ChecklistItem title="Create an account" child={<span className="text-md select-none text-glass-600">Create an account</span>} completed={true} />
          <ChecklistItem
            openDefault={true}
            title="Add your first document"
            child={
              <div>
                <span className="text-md select-none pb-3 text-glass-600">You can upload PDFs, text documents, and web links.</span>
                <Button onClick={openDocumentUpload} className="w-full" type="button">
                  <span className="text-lg text-white">Upload document</span>
                </Button>
              </div>
            }
            completed={hasUploadedDocument}
          />
          <ChecklistItem
            title="Listen to your Google doc"
            child={
              <div>
                <span className="text-md select-none pb-3 text-glass-600">
                  {t('Install the Speechify extension to listen to any webpages and to unlock superpower on Google doc and Gmail')}
                </span>
                <Button
                  onClick={() => {
                    if (chromeExtensionInstalled) {
                      window.open('https://docs.google.com/document/d/1L1SX0BUB7EgoDAx0cRfddHGs2-xPXxEqgRG02fUFcJI', '_blank');
                    } else {
                      window.open('https://docs.google.com/document/d/1FQec0SfJP0h9C81v6g9kQXAl-yqzWVjHsdyXszSgyMM', '_blank');
                    }
                    logSegmentEvent('web_app_onboarding_open_google_doc', { extension_installed: chromeExtensionInstalled });
                    dispatch(onboardingActions.setGoogleDocOpened(true));
                  }}
                  className="w-full"
                  type="button"
                >
                  <span className="text-lg text-white">Open Google Docs</span>
                </Button>
              </div>
            }
            completed={googleDocOpened}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default Checklist;
