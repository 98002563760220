import React, { useEffect, useMemo, useState } from 'react';

import PremiumGift from 'assets/icons/premiumGift';
import { CloseIcon } from 'components/experience/controls/voiceSelector/icons';
import { FeatureNameEnum, useFeatureFlag } from 'hooks/useFeatureFlags';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IUser, Team } from 'interfaces';
import { logError } from 'lib/observability';
import { getReferralCount, isUserSubscriptionEligible } from 'lib/referrals';
import { auth, isMonthlySubscription, isPremium, isRewardSubscription, isYearlySubscription } from 'lib/speechify';
import Trans from 'next-translate/Trans';
import { useSelector } from 'store';
import { useLocalStorage } from 'usehooks-ts';
import { logSegmentEvent } from 'utils/analytics';
import { lessThanXDaysApart, toDate } from 'utils/dates';

import ReferralModal, { getReferralUser } from './ReferralModal';

export default function ReferralSideBanner() {
  const { t } = useTranslation('common');

  const [closeCount, setCloseCount] = useLocalStorage('referral_side_banner', '0');
  const [lastClosedAt, setLastClosedAt] = useLocalStorage('referral_side_banner_last_closed_at', '0');

  const { team, isLoading: isTeamsLoading } = useSelector<{ team: Team; isLoading: boolean }>(state => state.team);
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  const { variant, isLoading } = useFeatureFlag(FeatureNameEnum.SAASQUATCH_REFERRAL);

  // state
  const [showBanner, setShowBanner] = useState(true); // always show by default
  const [showModal, setShowModal] = useState(false);
  const [hasReferralParam, setHasReferralParam] = useState(false); // Whether the referral param is present in the URL
  const [referralCount, setReferralCount] = useState<number | null>(null);
  const [wordsListenedData, setWordsListenedData] = useState(null);

  useEffect(() => {
    let shouldUpdate = true;

    const load = async () => {
      try {
        const url = '/api/stats/usage';

        const user = auth.currentUser;
        const token = await user?.getIdToken();
        const result = await (await fetch(url, { method: 'GET', cache: 'no-cache', headers: { Authorization: `Bearer ${token}` } })).json();
        const data = result?.data;

        if (shouldUpdate) {
          if (data && data.monthly && data.monthly.length > 0) {
            setWordsListenedData(data.monthly.at(-1).words);
          } else {
            // @ts-expect-error TS(2345): Argument of type '0' is not assignable to paramete... Remove this comment to see the full error message
            setWordsListenedData(0);
          }
        }
      } catch (e) {
        if (shouldUpdate) {
          // @ts-expect-error TS(2345): Argument of type '0' is not assignable to paramete... Remove this comment to see the full error message
          setWordsListenedData(0);
        }
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        logError(e);
      }
    };

    load();

    return () => {
      shouldUpdate = false;
    };
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const referralParam = url.searchParams.get('referral');

    if (referralParam === 'true') {
      logSegmentEvent('web_app_referral_widget_opened', { source: 'url_param' });
      setShowModal(true);
      setHasReferralParam(true);
    }
  }, []);

  useEffect(() => {
    if (isTeamsLoading || team || !isUserSubscriptionEligible(user)) return;
    logSegmentEvent('web_app_side_banner_shown', { source: 'side_banner' });
    // ESLint: React Hook useEffect has a missing dependency: 'user'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamsLoading, team]);

  useEffect(() => {
    getReferralCount(getReferralUser(user)).then(count => setReferralCount(count));
  }, [user]);

  useEffect(() => {
    if (hasReferralParam) return setShowBanner(true);
    if (parseInt(closeCount, 10) >= 2) return setShowBanner(false);

    // close if last close date is less than 4 hours
    if (lessThanXDaysApart(new Date(), toDate(lastClosedAt), 0.16)) return setShowBanner(false);

    // Always show if referral count is greater than 0
    if (referralCount !== null && referralCount > 0) {
      return setShowBanner(true);
    }

    // show if only 7 days left for monthly trial
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (isMonthlySubscription(user) && lessThanXDaysApart(toDate(user.subscription.renewsAt), new Date(), 7)) {
      return setShowBanner(true);
    }

    // 30 days for yearly one
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (isYearlySubscription(user) && lessThanXDaysApart(toDate(user.subscription.renewsAt), new Date(), 30)) {
      return setShowBanner(true);
    }

    // Hide the banner if user has listened less than 10k words
    // and above conditions are not satisfied
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (wordsListenedData < 10000) {
      return setShowBanner(false);
    }
    // ESLint: React Hook useEffect has a missing dependency: 'lastClosedAt'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCount, user, referralCount, wordsListenedData, hasReferralParam]);

  const handleClose = () => {
    const count = parseInt(closeCount, 10) + 1;
    logSegmentEvent('web_app_referral_widget_closed', { count, source: 'side_banner' });
    setShowBanner(false);
    setCloseCount(count.toString());
    setLastClosedAt(new Date().toISOString());
  };

  const handleClick = () => {
    logSegmentEvent('web_app_referral_widget_opened', { source: 'side_banner' });
    setShowModal(modal => !modal);
  };

  const freeUser = !isPremium(user);
  const hasRewardedPremium = isRewardSubscription(user);

  const titleText = useMemo(() => {
    if (freeUser || hasRewardedPremium) return t('Refer 2 Friends, Get Premium for free');
    // ESLint: Missing "key" prop for element in array & Missing "key" prop for element in array
    // eslint-disable-next-line react/jsx-key, react/jsx-key
    return <Trans i18nKey="common:Get $60 for free" components={[<span className="text-glass-500 line-through" />, <span style={{ color: '#2da71f' }} />]} />;
    // ESLint: React Hook useMemo has missing dependencies: 'freeUser', 'hasRewardedPremium', and 't'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bodyText = useMemo(() => {
    if (freeUser || hasRewardedPremium)
      return (
        <Trans
          i18nKey="Get 1 month <0>FREE</0> premium access with each qualifying referral. Invite 2 friends and a get full year <0>FREE</0>"
          // ESLint: Missing "key" prop for element in array
          // eslint-disable-next-line react/jsx-key
          components={[<strong />]}
        />
      );

    return t('Get $60 for free when you refer someone. They get $60 too.');
    // ESLint: React Hook useMemo has missing dependencies: 'freeUser', 'hasRewardedPremium', and 't'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // do not show if the variant is not correct
  if (isLoading || variant !== 'display') return null;
  if (isTeamsLoading) return null;

  // do not show if the user belongs to team or has an invalid subscription
  if (team || !isUserSubscriptionEligible(user)) return null;

  // When the referral param is present in the URL we don't want to have additional constraints and show the banner / widget
  // Since user is coming from a referral advertisement flow
  if (!hasReferralParam && (referralCount === null || wordsListenedData === null)) return null;

  return (
    <>
      {showBanner ? (
        <div className="relative mx-auto mt-4 box-content flex max-w-[220px] flex-col items-center justify-center gap-2 rounded-md p-4 px-2">
          <CloseIcon className="absolute right-0 top-6 -mt-5 mr-2 h-4 w-4 cursor-pointer fill-glass-500 hover:fill-glass-400" onClick={handleClose} />
          <div className="flex w-full justify-center">
            <PremiumGift />
          </div>

          <h4 className="w-full text-center text-lg font-bold leading-6 text-glass-700">{titleText}</h4>
          <p className="text-center text-sm text-glass-500">{bodyText}</p>
          <button
            className="mt-2 w-full rounded-md bg-electric-350 p-2 text-center font-ABCDiatype text-sm font-bold tracking-wider text-white"
            onClick={handleClick}
          >
            {t('Refer friends')}
          </button>

          {showModal && <ReferralModal open={showModal} onClose={() => setShowModal(false)} />}
        </div>
      ) : null}
    </>
  );
}
