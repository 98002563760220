import { useEffect } from 'react';

import { ErrorSource } from 'constants/errors';
import { IUser } from 'interfaces';
import { logError } from 'lib/observability';
import {
  auth,
  isApple,
  isLifetime,
  isPayPal,
  isPlayStore,
  isPremium,
  isRewardSubscription,
  isStripe,
  subscriptionCurrency,
  subscriptionPrice
} from 'lib/speechify';

import type { EngagementMedium } from '@saasquatch/squatch-js';

const TENANT_ID = process.env.NEXT_PUBLIC_SAASQUATCH_TENANT_ID ?? 'test_ao8ul52mq3bm9';
const PROJECT_ID = process.env.NEXT_PUBLIC_SAASQUATCH_PROJECT_ID ?? 'test';

export type ReferralUser = {
  id: string;
  accountId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  locale?: string;
};

type Squatch = typeof import('@saasquatch/squatch-js');

let squatch: Squatch | null;

/**
 * If the user has Stripe, PayPal, and Apple subscriptions with price < 139
 * or has a PlayStore subscription with a price < 69
 * then returns false
 */

const isUserSubscriptionEligible = (user: IUser | null) => {
  // lifetime and free users are eligible to refer people
  // @ts-expect-error TS(2345): Argument of type 'IUser | null' is not assignable ... Remove this comment to see the full error message
  if (isLifetime(user)) return true;
  if (!isPremium(user)) return true;
  // @ts-expect-error TS(2345): Argument of type 'IUser | null' is not assignable ... Remove this comment to see the full error message
  if (isRewardSubscription(user)) return true;

  // some currencies have very low prices - so we should not offer a fixed amount referral discounts
  const blockedCurrencies = ['PHP', 'INR', 'BRL', 'IDR'];
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  if (blockedCurrencies.includes(subscriptionCurrency(user))) return false;

  // premium users with non-standard pricing are not eligible
  // @ts-expect-error TS(2345): Argument of type 'IUser | null' is not assignable ... Remove this comment to see the full error message
  if (isStripe(user) || isApple(user) || isPayPal(user)) {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (subscriptionPrice(user) < 139) return false;
  }

  // premium users on play store with non stanard pricing are not eligible
  // @ts-expect-error TS(2345): Argument of type 'IUser | null' is not assignable ... Remove this comment to see the full error message
  if (isPlayStore(user) && subscriptionPrice(user) < 69) return false;

  return true;
};

const initReferral = async () => {
  return import('@saasquatch/squatch-js').then(module => {
    squatch = module;
    squatch.init({
      tenantAlias: TENANT_ID
    });
  });
};

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateJWT = async (data: Record<string, any>): Promise<{ token: string | null }> => {
  return new Promise(resolve => {
    auth.onAuthStateChanged(async user => {
      try {
        if (!user) throw new Error('User not logged in');
        const token = await user.getIdToken();
        const result = await fetch('/api/referral/sign', { method: 'POST', body: JSON.stringify(data), headers: { Authorization: `Bearer ${token}` } });
        const jwt = await result.json();
        resolve(jwt);
      } catch (e) {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        logError(e, ErrorSource.AUTH);
        resolve({ token: null });
      }
    });
  });
};

const getReferralCount = async (user: ReferralUser): Promise<number> => {
  try {
    const { token: jwt } = await generateJWT({ user });
    const result = await fetch('/api/referral/count', {
      method: 'GET',
      headers: { Authorization: `Bearer ${jwt}` }
    });
    const { count } = await result.json();
    return count;
  } catch (e) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(e);
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    throw new Error('Unable to get referral count from API', e);
  }
};

const setupEmbedForUser = async (user: ReferralUser) => {
  const { token: jwt } = await generateJWT({ user });
  const config = {
    user,
    engagementMedium: 'EMBED' as EngagementMedium,
    widgetType: `p/${PROJECT_ID}/w/referrerWidget`,
    jwt
  };
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  squatch?.widgets().upsertUser(config).then(console.log).catch(console.error);
};

const useReferralWidget = (user: ReferralUser) => {
  useEffect(() => {
    if (user.id) {
      initReferral().then(() => setupEmbedForUser(user));
    }
    // ESLint: React Hook useEffect has a missing dependency: 'user'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
};

export { generateJWT, getReferralCount, initReferral, isUserSubscriptionEligible, setupEmbedForUser, useReferralWidget };
