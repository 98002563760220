import React, { useEffect } from 'react';

import Sparkles from 'assets/icons/sparkles';
import { CloseIcon } from 'components/experience/controls/voiceSelector/icons';
import { logSegmentEvent } from 'utils/analytics';

export default function StudioTopBanner({ isOpen, onClick, onClose }: { isOpen: boolean; onClick: () => void; onClose: () => void }) {
  useEffect(() => {
    if (!isOpen) return;
    logSegmentEvent('web_app_studio_top_banner_shown');
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="relative flex h-[56px] items-center justify-center gap-4" style={{ background: 'linear-gradient(131deg, #0d22f3 0%, #7b00f5 100%)' }}>
      <span className="font-medium text-glass-0">Want to download voice overs or even use your cloned voice to listen to anything you want?</span>
      <button onClick={onClick} className="flex items-center gap-2 rounded-lg bg-glass-0 px-4 py-1.5 font-medium text-glass-700">
        <Sparkles /> Try Speechify Studio
      </button>
      <CloseIcon onClick={onClose} className="absolute right-4 top-5 h-4 w-4 cursor-pointer fill-glass-0 hover:fill-glass-200" />
    </div>
  );
}
