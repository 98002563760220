import React from 'react';
import { useMemo, useRef } from 'react';

import Button from 'components/elements/Button';
import ModalComponent from 'components/elements/Modal';
import { useTranslation } from 'hooks/useTypedTranslation';
import nookies from 'nookies';
import { ExtensionType, getRecommendedExtensionToInstall } from 'utils/extension';

const FEATURES_LIST = [
  {
    text: 'Listen your messages',
    image: 'images/onboarding/extInstallPopup/Messages.svg'
  },
  {
    text: 'Work or class notes',
    image: 'images/onboarding/extInstallPopup/Notes.svg'
  },
  {
    text: 'Online articles & news',
    image: 'images/onboarding/extInstallPopup/News.svg'
  },
  {
    text: 'Social Media',
    image: 'images/onboarding/extInstallPopup/Media.svg'
  }
];

const ExtInstallationModal: React.FC<{ setOpen: React.Dispatch<React.SetStateAction<boolean>>; open: boolean }> = ({ setOpen, open }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const onClose = () => {
    nookies.set(undefined, 'skipExtensionInstallation', 'false', { domain: '.speechify.com' });
    setOpen(false);
  };

  const recommendedExtension = useMemo(() => getRecommendedExtensionToInstall(), []);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity">
      <div ref={carouselRef} className="flex h-full w-full items-center justify-center">
        <ModalComponent open={open} showCloseButton onClose={onClose} classNames={`sm:max-w-xl`}>
          <div className="flex h-full flex-none flex-col justify-center px-4 py-4 sm:px-6 sm:py-10" style={{ width: 576 }}>
            <div className="flex flex-col items-center px-4">
              <h3 className="mb-0.5 text-center font-recoleta text-3xl">Listen to Anything</h3>
              <p className="text-center text-base">
                Speechify reads anything! Save time and energy <br /> by using our chrome extension.
              </p>
              <div className="mx-auto mb-6 mt-8">
                {FEATURES_LIST.map(({ text, image }) => {
                  return (
                    <div key={text} className="mb-6 flex">
                      <img src="/images/onboarding/extInstallPopup/GoldTickMark.svg" alt="" />
                      <p className="ml-6 mr-7 text-lg font-bold">{text}</p>
                      <img src={image} alt="" className="ml-auto" />
                    </div>
                  );
                })}
                <Button
                  className="mt-10 w-full border-mauve0 font-ABCDiatype text-lg font-bold"
                  color="primary"
                  title={t('Try Speechify Chrome Extension', { browser: recommendedExtension.extensionType === ExtensionType.EDGE ? 'Edge' : 'Chrome' })}
                  onClick={() => {
                    window.open(recommendedExtension.extensionStoreUrl);
                    onClose();
                  }}
                />
              </div>
              <div onClick={onClose} className="cursor-pointer text-center text-sm text-electric-400">
                Skip for now
              </div>
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};

export default ExtInstallationModal;
