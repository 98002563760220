import React, { Fragment, useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useDispatch } from 'store';
import { actions as importActions } from 'store/import';

import { PlusIcon } from '@heroicons/react/outline';
import TeamBanner from './TeamBanner';

interface NavProps {
  mobileAction?: string;
}

export const Nav: React.FC<NavProps> = ({ mobileAction }) => {
  const mounted = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleOpenImportDialogClick = useCallback(() => {
    dispatch(importActions.openImportDialog());
  }, [dispatch]);

  const handleTeamBannerClick = useCallback(() => {
    navigate('/settings?tab=Team');
    // ESLint: React Hook useCallback has a missing dependency: 'navigate'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (mobileAction) {
      case 'pdf-document':
        dispatch(importActions.openImportDialog());
        break;

      case 'text-document':
        dispatch(importActions.openImportDialog({ source: 'text' }));
        break;

      case 'web-link':
        dispatch(importActions.openImportDialog({ source: 'web' }));
        break;
    }
  }, [mobileAction, dispatch]);

  return (
    <Fragment>
      <TeamBanner onClick={handleTeamBannerClick} />

      <div className="relative z-50 mb-2">
        <button
          onClick={handleOpenImportDialogClick}
          className="mt-1 flex w-full items-center rounded-[10px] border font-ABCDiatype bg-electric-400  p-3 hover:bg-[#1D2FDA] active:bg-[#1A2BC5]"
        >
          <PlusIcon className="inline-block h-5 w-6 text-glass-0" aria-hidden="true" />
          <span className="ml-3 truncate text-base leading-6 font-normal text-glass-0 ">{t('New')}</span>
        </button>
      </div>
    </Fragment>
  );
};

export default Nav;
