import React from 'react';
import { useDispatch, useSelector } from 'store';
import { actions as onboardingActions, selectors as onboardingSelectors } from 'store/onboarding';

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.80201 17.9456C4.39186 18.3557 4.37232 19.0881 4.81178 19.5178C5.24147 19.9475 5.97389 19.9378 6.38404 19.5276L12.3606 13.5413L18.3469 19.5276C18.7669 19.9475 19.4895 19.9475 19.9192 19.5178C20.3391 19.0784 20.3489 18.3655 19.9192 17.9456L13.9426 11.9592L19.9192 5.98268C20.3489 5.56275 20.3489 4.8401 19.9192 4.41041C19.4797 3.99049 18.7669 3.98072 18.3469 4.40065L12.3606 10.387L6.38404 4.40065C5.97389 3.99049 5.2317 3.97096 4.81178 4.41041C4.38209 4.8401 4.39186 5.57252 4.80201 5.98268L10.7883 11.9592L4.80201 17.9456Z"
      fill="white"
    />
  </svg>
);

const OnboardingButton = () => {
  const onboardingProgress = useSelector(onboardingSelectors.getOnboardingProgress());
  const minimized = useSelector(state => !state.onboarding.checklistShowing);
  const dispatch = useDispatch();

  return (
    <div className="fixed bottom-4 right-24 z-2000">
      <div
        className=" cursor-pointer rounded-full bg-electric-300"
        onClick={() => {
          dispatch(onboardingActions.setChecklistShowing(minimized));
        }}
      >
        {minimized ? (
          <div className="flex h-12 select-none items-center justify-center px-4 font-ABCDiatype text-lg text-white">
            Get Started | {onboardingProgress.percentage}% Completed
          </div>
        ) : (
          <div className="flex h-12 w-12 items-center justify-center ">
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingButton;
