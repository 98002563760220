import React from 'react';
import { useEffect, useMemo } from 'react';

import { logVariant, useFeatureFlag, FeatureNameEnum } from 'hooks/useFeatureFlags';
import { IUser } from 'interfaces';
import { useSelector } from 'store';
import { getDailyListeningGoal } from 'store/gamification/selectors';
import { logSegmentEvent } from 'utils/analytics';

import { useIsGamificationEnabled } from './hooks/useIsGamificationEnabled';
import { SetDailyListeningGoalModal } from './SetDailyListeningGoalModal';

const GAMIFICATION_PROMPT_EXPERIMENT_NEW_USER_TIME = new Date('2023-02-19');

const GAMIFICATION_PROMPT_EXPERIMENT_NAME = FeatureNameEnum.GAMIFICATION_SET_GOAL_PROMPT;

const cacheKey = (userId: string) => `hasSeenGamificationPromptExperiment-${userId}`;

export function GamificationSetGoalPrompExperimentModal(): JSX.Element {
  const isGamificationEnabled = useIsGamificationEnabled();
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  const isNewUser = useMemo<boolean>(() => {
    const creationDate = new Date(user?.metadata?.creationTime || 0);
    return creationDate.getTime() > GAMIFICATION_PROMPT_EXPERIMENT_NEW_USER_TIME.getTime();
  }, [user]);

  const { variant, isLoading } = useFeatureFlag(GAMIFICATION_PROMPT_EXPERIMENT_NAME);
  const dailyListeningGoal = useSelector(getDailyListeningGoal);

  const shouldShowGamificationPromptExperiment = useMemo<boolean>(() => {
    // Only show the modal for new users who are assigned to the Gamification tests.
    // Wait until the feature flag is loaded before showing the modal.
    if (!isGamificationEnabled || !isNewUser || isLoading) return false;
    logVariant(GAMIFICATION_PROMPT_EXPERIMENT_NAME, variant);

    if (variant !== 'modal') return false;

    const hasSeenGamificationModal = localStorage.getItem(cacheKey(user?.uid || ''));

    if (hasSeenGamificationModal) {
      return false;
    }

    // don't show the modal if the user has already set a goal
    if (dailyListeningGoal) {
      return false;
    }

    return true;
    // ESLint: React Hook useMemo has a missing dependency: 'user?.uid'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGamificationEnabled, isNewUser, variant, isLoading, dailyListeningGoal]);

  useEffect(() => {
    if (user && shouldShowGamificationPromptExperiment) {
      localStorage.setItem(cacheKey(user?.uid || ''), 'true');
      logSegmentEvent('web_app_gamification_prompt_experiment_modal_seen');
    }
  }, [shouldShowGamificationPromptExperiment, user]);

  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Element'.
  if (!shouldShowGamificationPromptExperiment) return null;

  return <SetDailyListeningGoalModal />;
}
